import { useNavigate } from "react-router-dom";

import { Container } from "../../../components/Container/Container";
import { Header } from "../../../components/Header/Header";
import { Footer } from "../../../components/Footer/Footer";
import { TransactionsGroupProvider } from "../../context/TransactionsGroupProvider";
import { TransactionsGroupSuccessDetails } from "./TransactionsGroupSuccessDetails";

export const TransactionsGroupSuccess = () => {
  const navigate = useNavigate();

  return (
    <TransactionsGroupProvider>
      <Container>
        <div className="relative flex flex-col px-[24px] lg:px-0">
          <Header title="Success" />
          <TransactionsGroupSuccessDetails />
        </div>
      </Container>
      <Footer
        continueTitle="Back to transactions groups"
        isValid={true}
        handleContinue={() => navigate("/admin/transactions-groups")}
      />
    </TransactionsGroupProvider>
  );
};
