import { useEffect, useState } from "react";

import { Card } from "@/components/Card/Card";
import { formatCurrency, getAddressAsString } from "@shared/utils";
import { Lozenge } from "@/components/Lozenge/Lozenge";
import { Table, TableProps } from "../Table/Table";
import { TagsModal } from "../Modals/TagsModal";
import { useTransactionContext } from "../../context/TransactionProvider";
import { getOrderType } from "@utils";

type TableAndCopyOptions = TableProps & { includeCopyButton?: boolean };

export const TransactionInfo = () => {
  const { transaction, setTransaction } = useTransactionContext();

  const [fields, setFields] = useState<TableAndCopyOptions[]>([]);
  const [showEditTagsModal, setShowEditTagsModal] = useState(false);

  useEffect(() => {
    if (transaction) {
      const { lawyer_group, estate_agent, group } = transaction;
      const { address, tenure } = transaction.property_id;
      const estateAgentString =
        estate_agent && `${estate_agent?.brand}-${estate_agent?.office.name}`;

      setFields([
        {
          label: "ID",
          value: transaction._id,
          includeCopyButton: true,
        },
        {
          label: "Transaction Type",
          value: transaction.type,
        },
        {
          label: "Property",
          value: getAddressAsString(address, true),
          includeCopyButton: true,
        },
        {
          label: "Tenure",
          value: tenure,
        },
        {
          label: "Asking Price",
          value: formatCurrency(transaction.asking_price, true, true),
        },
        {
          label: "Payment Type",
          // FIXME: Refactor when Orders becomes it's own collection
          value: getOrderType(
            transaction.orders[transaction.orders.length - 1],
          ),
        },
        {
          label: "Seller Solicitor",
          value: lawyer_group?.seller.name || "",
        },
        {
          label: "Buyer Solicitor",
          value: lawyer_group?.buyer.name || "",
        },
        {
          label: "Estate Agent",
          value: estateAgentString || "",
        },
        {
          label: "Group Name",
          value: group?.name || "",
        },
      ]);
    }
  }, [transaction]);

  return (
    transaction &&
    setTransaction && (
      <>
        <Card>
          <div className="md:grid lg:grid-rows-4 grid-rows-6 grid-flow-col">
            {fields.map(({ value, label, includeCopyButton }) => (
              <div key={label} className="flex jusify-top">
                <Table
                  key={value as string}
                  label={label}
                  value={value}
                  includeCopyButton={includeCopyButton}
                />
              </div>
            ))}
          </div>
        </Card>

        <div className="flex flex-wrap gap-[8px] flex-row mt-[16px]">
          {transaction.tags &&
            transaction.tags.map((tag) => (
              <div key={`tag-${tag}`} className="mb-[4px]">
                <Lozenge>{tag}</Lozenge>
              </div>
            ))}

          <button
            onClick={() => setShowEditTagsModal(true)}
            type="button"
            className="mb-[4px]"
          >
            <Lozenge
              backgroundColor="bg-brand-heavy-teal-100"
              fontColor="text-ui-white-100"
            >
              +/- Tags
            </Lozenge>
          </button>
        </div>

        {showEditTagsModal && (
          <TagsModal
            onClose={() => setShowEditTagsModal(false)}
            onSavedTags={(newTransaction) => {
              setTransaction(newTransaction);
              setShowEditTagsModal(false);
            }}
            selectedTags={transaction.tags || []}
            transactionId={transaction._id}
          />
        )}
      </>
    )
  );
};
