import { useEffect, useState } from "react";
import { Field, FieldProps, Form, Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";
import { useParams } from "react-router-dom";

import Typography from "@/foundation/Typography/Typography";
import { Modal } from "../../../components/Modal/Modal";
import { ApiController } from "../../../service/ApiController";
import { ConfirmCard } from "../AssignSolicitors/ConfirmCard";
import ErrorText from "@/foundation/Typography/ErrorText";
import { Select } from "@/components/Input/Select";
import { EstateAgent } from "../../../types";
import { useTransactionContext } from "../../context/TransactionProvider";
import { TransactionsGroupState } from "@schema";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import {
  getEstateAgent,
  getEstateAgentOptions,
  getVariant,
  isEmailForPrimaryValuer,
} from "./utils";
import { WarningCard } from "../WarningCard/WarningCard";
import { entityAlreadyAssignedWarning } from "../WarningCard/WarningTexts";
import { isTransactionGroupAndEntityAssigned } from "../../pages/TransactionsGroupDetails/utils";

interface AssignEstateAgentBlockProps {
  onCloseModal: () => void;
  isTransactionsGroup: boolean;
  isEntityAssigned?: boolean;
}

export const AssignEstateAgentBlock = ({
  onCloseModal,
  isTransactionsGroup,
  isEntityAssigned,
}: AssignEstateAgentBlockProps) => {
  const { transaction, setTransaction } = useTransactionContext();
  const { setTransactionsGroup } = useTransactionsGroupContext();

  const { id: groupId } = useParams<{ id: string }>();

  const [displayConfirmCard, setDisplayConfirmCard] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [estateAgents, setEstateAgents] = useState<EstateAgent[]>([]);
  const [errorFetching, setErrorFetching] = useState("");
  const [estateAgentId, setEstateAgentId] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchEstateAgents() {
      try {
        const { items } = await ApiController.findAllEstateAgents({});

        setEstateAgents(items);
      } catch (error) {
        if (error instanceof Error) {
          setErrorFetching(error.message);
        }
      }
    }

    fetchEstateAgents();
  }, []);

  useEffect(() => {
    setEstateAgentId(transaction?.estate_agent?._id || "");
  }, [transaction]);

  const handleConfirmAssignEstateAgents = async (
    estateAgentIdSelected: string,
  ) => {
    setIsInputDisabled(true);

    try {
      const estateAgent = getEstateAgent(estateAgents, estateAgentIdSelected);

      if (estateAgent) {
        if (isTransactionsGroup) {
          assignEstateAgentToTransactionsGroup(estateAgent);
        } else {
          assignEstateAgentToSingleTransaction(estateAgent);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }

    setIsInputDisabled(false);
  };

  const assignEstateAgentToSingleTransaction = async (
    estateAgent: EstateAgent,
  ) => {
    if (!transaction || !setTransaction) {
      throw new Error(`Transaction not found with id ${transaction?._id}`);
    }

    // Add estate agent to transaction in DB
    const updatedTransaction = await ApiController.updateEstateAgentTransaction(
      transaction._id,
      estateAgent._id,
    );

    setTransaction(updatedTransaction);
    onCloseModal();
  };

  const assignEstateAgentToTransactionsGroup = async (
    estateAgent: EstateAgent,
  ) => {
    // Add estate agent to group of transactions in DB
    if (!groupId) {
      throw new Error("Transaction group not found");
    }

    await ApiController.updateEstateAgentTransactionsGroup(
      groupId,
      estateAgent._id,
    );

    setTransactionsGroup((prev: TransactionsGroupState) => ({
      ...prev,
      estateAgent,
    }));

    onCloseModal();
  };

  const EstateAgentForm = z.object({
    estate_agent: z.string().min(1, { message: "Estate agent is required" }),
  });

  return (
    <Formik
      initialValues={{
        estate_agent: estateAgentId || "",
      }}
      validate={withZodSchema(EstateAgentForm)}
      onSubmit={(values) =>
        handleConfirmAssignEstateAgents(values.estate_agent)
      }
    >
      {({ handleSubmit, errors, values }) => {
        return (
          <Form>
            <Modal
              title="Estate Agent"
              cancelButtonFunction={() => onCloseModal()}
              saveButtonFunction={() => setDisplayConfirmCard(true)}
              hideSaveButton={
                displayConfirmCard ||
                errorFetching !== "" ||
                isTransactionGroupAndEntityAssigned(
                  isTransactionsGroup,
                  isEntityAssigned,
                )
              }
            >
              <>
                {isTransactionGroupAndEntityAssigned(
                  isTransactionsGroup,
                  isEntityAssigned,
                ) ? (
                  <WarningCard
                    text={entityAlreadyAssignedWarning("Estate Agent")}
                  />
                ) : (
                  <>
                    <Typography
                      type="h3"
                      variant="h3"
                      weight="bold"
                      className="text-brand-heavy-teal-100 mb-[16px] mt-[40px]"
                    >
                      Assign Estate Agent
                    </Typography>
                    {errorFetching ? (
                      <ErrorText>{errorFetching}</ErrorText>
                    ) : (
                      <div
                        className="flex flex-col w-[60%]"
                        data-testid="assign-estate-agent"
                      >
                        <Field name="estate_agent">
                          {({ field }: FieldProps) => {
                            return (
                              <Select
                                {...field}
                                name="estate_agent"
                                id="estate_agent"
                                options={getEstateAgentOptions(estateAgents)}
                                variant={getVariant(
                                  errors["estate_agent"],
                                  isInputDisabled || displayConfirmCard,
                                )}
                              />
                            );
                          }}
                        </Field>
                        <div className="mt-[16px]">
                          <ErrorText>{errors.estate_agent}</ErrorText>
                          <ErrorText>{error}</ErrorText>
                        </div>
                      </div>
                    )}
                    {displayConfirmCard && (
                      <ConfirmCard
                        setDisplayConfirmCard={setDisplayConfirmCard}
                        handleConfirmAssign={handleSubmit}
                        type="estate agent"
                        extraInfo={
                          !isEmailForPrimaryValuer(
                            values.estate_agent,
                            estateAgents,
                          ) ? (
                            <WarningCard
                              text="Please note, there is no email for the primary valuer,
                          an introduction email will not be sent."
                            />
                          ) : undefined
                        }
                      />
                    )}
                  </>
                )}
              </>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
