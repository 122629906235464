import { useCallback, useEffect, useRef, useState } from "react";
import { Field, FieldProps } from "formik";

import { GenericCheckbox } from "@/components/Checkbox/GenericCheckbox";
import Icons from "@/foundation/Icons/Icons";

interface CheckboxProps {
  fieldName: string;
  fieldLabel: string;
  tooltipText?: string;
  disabled: boolean;
}

export const Checkbox = ({
  fieldName,
  fieldLabel,
  tooltipText,
  disabled,
}: CheckboxProps) => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target as Node)
    ) {
      setTooltipVisible(false);
    }
  }, []);

  useEffect(() => {
    if (isTooltipVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTooltipVisible, handleClickOutside]);

  return (
    <>
      <Field name={fieldName}>
        {({ field }: FieldProps) => {
          return (
            <div className="relative flex items-center space-x-2">
              <GenericCheckbox
                {...field}
                label={fieldLabel}
                defaultChecked={true}
                checked={disabled ? false : field.value}
                variant={disabled ? "disabled" : "enabled"}
              />
              <div className="relative group">
                <button
                  type="button"
                  onClick={() => setTooltipVisible((prev) => !prev)}
                  onBlur={() => setTooltipVisible(false)}
                  className="cursor-pointer"
                >
                  <Icons iconName="Info" size="16" />
                </button>
                {tooltipText && isTooltipVisible && (
                  <div
                    ref={tooltipRef}
                    className="absolute z-50 left-0 top-[25px] bg-brand-heavy-teal-75 text-ui-white-100 px-[12px] py-[8px] rounded-[4px] duration-300 ease-in-out w-[250px]"
                  >
                    {tooltipText}
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </Field>
    </>
  );
};
