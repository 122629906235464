import Icons from "../../foundation/Icons/Icons";
import Typography from "../../foundation/Typography/Typography";

import { CheckboxProps } from "./Checkbox.types";

export const GenericCheckbox = ({
  label,
  variant = "enabled",
  name,
  ...props
}: CheckboxProps) => (
  <label className="flex items-center">
    <div className="relative">
      <input
        {...props}
        type="checkbox"
        name={name}
        disabled={variant === "disabled"}
        className="peer appearance-none w-[16px] h-[16px] border-2 rounded-sm border-brand-heavy-teal-75 bg-ui-white-100 checked:bg-brand-heavy-teal-75"
      />
      <div className="hidden peer-checked:block absolute top-[45%] left-[50%] -translate-x-[50%] -translate-y-[55%]">
        <Icons iconName="Checkmark" colour="complement" size="10" />
      </div>
    </div>

    <Typography
      type="p"
      variant="sm"
      weight="semi-bold"
      className={`ml-[8px] ${variant === "disabled" ? "text-brand-heavy-teal-50" : "text-brand-heavy-teal-100"}`}
    >
      {label}
    </Typography>
  </label>
);
