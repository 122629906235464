import Typography from "@/foundation/Typography/Typography";
import { Button } from "@/components/Button/Button";
import { AssignEstateAgentBlock } from "../../components/AssignEstateAgent/AssignEstateAgentBlock";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import Icons from "@/foundation/Icons/Icons";
import { AssignSolicitorsBlock } from "../../components/AssignSolicitors/AssignSolicitorsBlock";
import { Entity } from "../../types/types";

interface OpsActionsProps {
  entity: Entity;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}

export const OpsActions = ({
  entity,
  isModalOpen,
  setIsModalOpen,
}: OpsActionsProps) => {
  const { transactionsGroup } = useTransactionsGroupContext();

  const isEntityAssigned =
    entity === "Estate Agent"
      ? !!transactionsGroup?.estateAgent
      : !!transactionsGroup?.lawyerGroups;

  const renderEntityBlock = () => {
    if (!isModalOpen) return null;
    const commonProps = {
      onCloseModal: () => setIsModalOpen(false),
      isTransactionsGroup: true,
      isEntityAssigned,
    };
    return entity === "Estate Agent" ? (
      <AssignEstateAgentBlock {...commonProps} />
    ) : (
      <AssignSolicitorsBlock {...commonProps} />
    );
  };

  return (
    <>
      <div className="flex items-center gap-[8px]">
        {isEntityAssigned && <Icons iconName="GreenTick" size="24" />}
        <Typography
          variant="lg"
          weight="bold"
          className="text-brand-heavy-teal-100"
        >
          Step {entity === "Estate Agent" ? "1:" : "2:"}
        </Typography>
        <Typography variant="lg" className="text-brand-heavy-teal-100">
          Assign {entity} to all transactions in this group
        </Typography>
      </div>
      <div className="mt-[24px]">
        <Button
          type="button"
          onClick={() => setIsModalOpen(true)}
          variant="primary"
          size="lg"
        >
          Assign {entity}
        </Button>
      </div>
      {isModalOpen && renderEntityBlock()}
    </>
  );
};
