import Typography from "@/foundation/Typography/Typography";
import { CopyButton } from "../CopyButton/CopyButton";

import {
  AdminTransaction,
  Giftors,
  Intent,
  Item,
  Order,
  Seller,
} from "../../pages/TransactionDetails/types";
import { formatCurrency, preprocessDecodedHTML } from "@shared/utils";
import {
  Address,
  AdminProfile,
  AdminUser,
} from "../../pages/UserDetails/types";

export interface TableProps {
  label: string;
  value?: string;
  isSmall?: boolean;
  includeCopyButton?: boolean;
}

export const getValue = (
  obj:
    | Intent
    | Giftors
    | Order
    | Item
    | AdminTransaction
    | Seller
    | Address
    | AdminUser
    | AdminProfile
    | AdminUser,
  key: string,
): string => {
  const value = obj[
    key as keyof (
      | Intent
      | Giftors
      | Order
      | Item
      | AdminTransaction
      | Seller
      | Address
      | AdminUser
      | AdminProfile
    )
  ] as string | number | boolean;
  if (typeof value === "string" || typeof value === "boolean") {
    return value.toString();
  } else if (typeof value === "number") {
    return formatCurrency(value, true, true).toString();
  } else {
    return "";
  }
};

const generateDefaultValue = (label: string): string => {
  if (label.includes("Solicitor") || label.includes("Agent")) {
    return "Not Yet Assigned";
  } else if (label.includes("Group Name")) {
    return "None";
  } else {
    return "Unknown";
  }
};

export const Table: React.FC<TableProps> = ({
  label,
  value = "Unknown",
  isSmall,
  includeCopyButton = false,
}: TableProps) => {
  const defaultValue = generateDefaultValue(label);
  const displayValue = preprocessDecodedHTML(value) || defaultValue;

  return (
    <div className="flex items-center text-brand-heavy-teal-100 mb-[12px]">
      <div className="flex w-full">
        <Typography weight="bold" variant={isSmall ? "sm" : "base"}>
          {label}:
        </Typography>

        {includeCopyButton ? (
          <CopyButton value={displayValue} label={label}>
            <Typography
              className={`px-[6px] ${!label.includes("Email") ? "capitalize" : ""}`}
              variant={isSmall ? "sm" : "base"}
            >
              {displayValue}
            </Typography>
          </CopyButton>
        ) : (
          <Typography
            className={`px-[12px] ${!label.includes("Email") ? "capitalize" : ""}`}
            variant={isSmall ? "sm" : "base"}
          >
            {displayValue}
          </Typography>
        )}
      </div>
    </div>
  );
};
