import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { withZodSchema } from "formik-validator-zod";

import { ApiController } from "../../../service/ApiController";
import { Button } from "@/components/Button/Button";
import { ContactFormFormikValues } from "../../../components/Types";
import { ContactFormSchema } from "../../../schema";
import { useFormikStore } from "../../../store/Formik/formik";
import ErrorText from "@/foundation/Typography/ErrorText";
import TextInput from "@/components/Input/TextInput";
import Typography from "@/foundation/Typography/Typography";

export const ContactForm = () => {
  const { setIsFormikReady, setSubmitForm } = useFormikStore();
  const [successMessage, setSuccessMessage] = useState<boolean>(false);

  const handleFormSubmit = async (values: ContactFormFormikValues) => {
    try {
      const response = await ApiController.requestCallback(values);
      setSuccessMessage(response);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  };

  const formik = useFormik<ContactFormFormikValues>({
    initialValues: {
      fullName: "",
      email: "",
      mobileNumber: "",
      message: "",
      referrer: "",
    },
    onSubmit: (values) => {
      handleFormSubmit(values);
      setSubmitForm(false);
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    validate: withZodSchema(ContactFormSchema),
  });

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    dirty,
    handleSubmit,
  } = formik;

  useEffect(() => {
    setIsFormikReady(isValid && dirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, dirty]);

  if (successMessage)
    return (
      <div className="max-w-[350px] lg:pl-[50px] text-center md:text-left">
        <Typography
          type="h2"
          variant="h3"
          weight="semi-bold"
          className="text-brand-heavy-teal-100 font-display mb-[16px]"
        >
          Your message has been sent!
        </Typography>

        <Typography
          type="p"
          variant="xl"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[16px]"
        >
          We will get back to you within 24 hours.
        </Typography>
      </div>
    );

  return (
    <form
      id="contact"
      className="w-full max-w-[400px] flex flex-col"
      onSubmit={handleSubmit}
    >
      <>
        <TextInput
          id="fullName"
          name="fullName"
          type="text"
          label="Full name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values["fullName"] || ""}
          variant={
            errors["fullName"] && touched["fullName"] ? "error" : "enabled"
          }
          className="max-w-[100%] mb-[16px]"
        />

        {errors["fullName"] && touched["fullName"] && (
          <div className="mt-[-8px] mb-[16px]">
            <ErrorText>{errors["fullName"] as string}</ErrorText>
          </div>
        )}
      </>

      <>
        <TextInput
          id="mobileNumber"
          name="mobileNumber"
          type="text"
          label="Mobile number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values["mobileNumber"] || ""}
          variant={
            errors["mobileNumber"] && touched["mobileNumber"]
              ? "error"
              : "enabled"
          }
          className="max-w-[100%] mb-[16px]"
        />

        {errors["mobileNumber"] && touched["mobileNumber"] && (
          <div className="mt-[-8px] mb-[16px]">
            <ErrorText>{errors["mobileNumber"] as string}</ErrorText>
          </div>
        )}
      </>

      <>
        <TextInput
          id="email"
          name="email"
          type="email"
          label="Email address"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values["email"] || ""}
          variant={errors["email"] && touched["email"] ? "error" : "enabled"}
          className="max-w-[100%] mb-[16px]"
        />

        {errors["email"] && touched["email"] && (
          <div className="mt-[-8px] mb-[16px]">
            <ErrorText>{errors["email"] as string}</ErrorText>
          </div>
        )}
      </>

      <>
        <TextInput
          id="referrer"
          name="referrer"
          type="text"
          label="How did you hear about us?"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values["referrer"] || ""}
          variant={
            errors["referrer"] && touched["referrer"] ? "error" : "enabled"
          }
          className="max-w-[100%] mb-[16px]"
        />

        {errors["referrer"] && touched["referrer"] && (
          <div className="mt-[-8px] mb-[16px]">
            <ErrorText>{errors["referrer"] as string}</ErrorText>
          </div>
        )}
      </>

      <>
        <TextInput
          id="message"
          name="message"
          type="text"
          label="How can we help?"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values["message"] || ""}
          variant={
            errors["message"] && touched["message"] ? "error" : "enabled"
          }
          className="max-w-[100%] mb-[16px]"
        />

        {errors["message"] && touched["message"] && (
          <div className="mt-[-8px] mb-[16px]">
            <ErrorText>{errors["message"] as string}</ErrorText>
          </div>
        )}
      </>

      <Button
        variant="primary"
        type="submit"
        onClick={() => setSubmitForm(true)}
        className="w-full"
        disabled={!isValid || !dirty}
      >
        Get Started
      </Button>
    </form>
  );
};
