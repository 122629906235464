import { Roles } from "@shared/types";
import { Checkbox } from "../../../components/Formik/Checkbox/Checkbox";
import { CheckboxConfigGroup } from "./utils";

interface CheckboxListProps {
  config: CheckboxConfigGroup;
  role: Roles;
}

export const CheckboxList = ({ config, role }: CheckboxListProps) => (
  <>
    {config[role].map(({ fieldName, fieldLabel, tooltipText, disabled }) => (
      <div className="mt-[12px]" key={fieldName}>
        <Checkbox
          fieldName={fieldName}
          fieldLabel={fieldLabel}
          tooltipText={tooltipText}
          disabled={disabled}
        />
      </div>
    ))}
  </>
);
