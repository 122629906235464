import { Card } from "@/components/Card/Card";
import { Table, TableProps } from "../Table/Table";
import { useEffect, useState } from "react";
import { getAddressAsString } from "@shared/utils";
import { AssignSolicitorsBlock } from "../AssignSolicitors/AssignSolicitorsBlock";
import Typography from "@/foundation/Typography/Typography";
import { Button } from "@/components/Button/Button";
import { getLawyerGroupName } from "../AssignSolicitors/utils";
import { LawyerGroup } from "../../../types";
import { useTransactionContext } from "../../context/TransactionProvider";
import { isTransactionPaidOrReferralCodeApplied } from "../../../utils/isTransactionPaidOrReferralCodeApplied";
import { WarningCard } from "../WarningCard/WarningCard";

const SolicitorDetails = ({
  lawyerGroup,
  keyPrefix,
}: {
  lawyerGroup?: LawyerGroup;
  keyPrefix: string;
}) => {
  const [fields, setFields] = useState<TableProps[]>([]);

  useEffect(() => {
    if (!lawyerGroup) return;

    const { panel_solicitor, law_firm, solicitor, legal_assistants } =
      lawyerGroup;

    setFields([
      {
        label: "Panel Solicitor",
        value: panel_solicitor ? "Yes" : "No",
      },
      {
        label: "Group Name",
        value: getLawyerGroupName(lawyerGroup) || "",
      },
      {
        label: "Law Firm Name",
        value: law_firm.name || "",
      },
      {
        label: "Law Firm Address",
        value: getAddressAsString(law_firm.address) || "",
      },
      {
        label: "Law Firm Email",
        value: law_firm.email || "",
      },
      {
        label: "Law Firm Phone Number",
        value: law_firm.phone_number || "",
      },
      {
        label: "Solicitor Name",
        value: solicitor
          ? `${solicitor.first_name} ${solicitor.last_name}`
          : "",
      },
      {
        label: "Solicitor Email",
        value: solicitor.email || "",
      },
      {
        label: "Solicitor Phone Number",
        value: solicitor.lawyer_profile?.phone_number || "",
      },
      {
        label: "Legal Assistant Names",
        value:
          legal_assistants
            ?.map(
              (assistant) => `${assistant.first_name} ${assistant.last_name}`,
            )
            .join(", ") || "",
      },
      {
        label: "Legal Assistant Emails",
        value:
          legal_assistants?.map((assistant) => assistant.email).join(", ") ||
          "",
      },
      {
        label: "Legal Assistant Phone Number",
        value:
          legal_assistants
            ?.map((assistant) => assistant.lawyer_profile?.phone_number)
            .join(", ") || "",
      },
    ]);
  }, [lawyerGroup]);

  return (
    <>
      {fields.map(({ value, label }) => (
        <Table key={`${keyPrefix}-${label}`} label={label} value={value} />
      ))}
    </>
  );
};

export const SolicitorTab = () => {
  const { transaction, transactionEvents, setTransaction } =
    useTransactionContext();

  const [transactionPaid, setTransactionPaid] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const transactionPaidOrOrReferralCodeApplied =
      isTransactionPaidOrReferralCodeApplied(transactionEvents);

    setTransactionPaid(transactionPaidOrOrReferralCodeApplied);
  }, [transactionEvents]);

  return (
    <div className="flex flex-col space-y-5">
      {!transaction?.lawyer_group && (
        <Card>
          <div className="flex flex-col items-center">
            <Typography variant="lg" weight="semi-bold" className="mb-[16px]">
              There are no solicitors assigned to this transaction.
            </Typography>

            {!transactionPaid && (
              <WarningCard text=" Please note, this transaction is unpaid" />
            )}

            <div className="flex gap-[16px] mt-[12px]">
              <Button
                type="button"
                onClick={() => {
                  setIsModalOpen(true);
                }}
                variant="primary"
                size="full"
              >
                Assign solicitors
              </Button>
            </div>
          </div>
        </Card>
      )}

      {isModalOpen && transaction && setTransaction && (
        <AssignSolicitorsBlock
          onCloseModal={() => setIsModalOpen(false)}
          isTransactionsGroup={false}
        />
      )}

      {transaction?.lawyer_group?.seller && (
        <Card
          title="Seller Solicitor"
          titleBackgroundColor="brand-heavy-warmth-25"
          titleColor="brand-heavy-teal-100"
          titleRightContent={
            <button
              // FIXME: Remove "hidden" class when reassign solicitor feature is implemented:
              className="brand-heavy-teal-100 underline hidden"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Edit
            </button>
          }
        >
          <SolicitorDetails
            lawyerGroup={transaction.lawyer_group?.seller}
            keyPrefix="seller-solicitor"
          />
        </Card>
      )}

      {transaction?.lawyer_group?.buyer && (
        <Card
          title="Buyer Solicitor"
          titleBackgroundColor="brand-heavy-warmth-25"
          titleColor="brand-heavy-teal-100"
          titleRightContent={
            <button
              // FIXME: Remove "hidden" class when reassign solicitor feature is implemented:
              className="brand-heavy-teal-100 underline hidden"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Edit
            </button>
          }
        >
          <SolicitorDetails
            lawyerGroup={transaction.lawyer_group?.buyer}
            keyPrefix="buyer-solicitor"
          />
        </Card>
      )}
    </div>
  );
};
