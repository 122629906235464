import { useEffect, useState, useMemo } from "react";
import { Field, FieldProps, useFormikContext } from "formik";

import Typography from "@/foundation/Typography/Typography";
import ErrorText from "@/foundation/Typography/ErrorText";
import Icons from "@/foundation/Icons/Icons";
import { Roles } from "@shared/types";
import { isSeller } from "@shared/utils";
import {
  isSolicitorNonPanel,
  checkboxConfigWithNoIntroductionToSellerEmail,
  checkboxIndividualTransactionConfig,
  checkboxTransactionsGroupConfig,
  convertRoleToLowerCase,
  getFieldValue,
  getLawyerGroupName,
  CheckboxConfigGroup,
} from "./utils";
import { LawyerGroupFormState } from "./AssignSolicitorsBlock";
import { Select } from "@/components/Input/Select";
import { LawyerGroup } from "../../../types";
import { useTransactionContext } from "../../context/TransactionProvider";
import { CheckboxList } from "./CheckboxList";

interface AssignSolicitorsProps {
  lawyerGroups: LawyerGroup[];
  isInputDisabled: boolean;
  displayConfirmCard: boolean;
  isSelectedSellerSolicitorNonPanel: boolean;
  isTransactionsGroup: boolean;
}

export const AssignSolicitors = ({
  lawyerGroups,
  isInputDisabled,
  displayConfirmCard,
  isSelectedSellerSolicitorNonPanel,
  isTransactionsGroup,
}: AssignSolicitorsProps) => {
  const { errors } = useFormikContext<LawyerGroupFormState>();
  const { transaction } = useTransactionContext();

  const [checkboxes, setCheckboxes] = useState<CheckboxConfigGroup>(
    checkboxIndividualTransactionConfig,
  );

  const lawyerGroupOptions = useMemo(() => {
    return [
      { label: "Select a solicitor", value: "", disabled: true },
      ...lawyerGroups.map((lawyerGroup) => ({
        label: `${getLawyerGroupName(lawyerGroup)} ${
          isSolicitorNonPanel(lawyerGroups, lawyerGroup._id)
            ? "(Non-Panel)"
            : ""
        }`,
        value: lawyerGroup._id,
      })),
    ];
  }, [lawyerGroups]);

  useEffect(() => {
    const config = isTransactionsGroup
      ? checkboxTransactionsGroupConfig
      : checkboxIndividualTransactionConfig;

    setCheckboxes(
      isSelectedSellerSolicitorNonPanel
        ? checkboxConfigWithNoIntroductionToSellerEmail(config)
        : config,
    );
  }, [isSelectedSellerSolicitorNonPanel, isTransactionsGroup]);

  return (
    <>
      {[Roles.Seller, Roles.Buyer].map((role) => {
        const lowerCaseRole = convertRoleToLowerCase(role);
        const roleLawyerGroup = `${lowerCaseRole}_lawyer_group`;
        const error = errors[roleLawyerGroup as keyof LawyerGroupFormState];

        return (
          <div key={role}>
            <Typography
              type="h2"
              variant="xl"
              weight="bold"
              className="text-brand-heavy-teal-100 my-[16px]"
            >
              {`${role} Solicitor`}
            </Typography>
            <div className="flex flex-col w-[60%]">
              <div
                className="flex flex-col w-[100%]"
                data-testid="assign-lawyer-group"
              >
                {/* Dropdown */}
                <Field name={roleLawyerGroup}>
                  {({ field }: FieldProps) => {
                    return (
                      <Select
                        {...field}
                        data-testid={roleLawyerGroup}
                        name={roleLawyerGroup}
                        id={roleLawyerGroup}
                        options={lawyerGroupOptions}
                        variant={
                          isInputDisabled || displayConfirmCard
                            ? "disabled"
                            : error
                              ? "error"
                              : "enabled"
                        }
                        value={getFieldValue(
                          transaction?.lawyer_group,
                          lowerCaseRole,
                          field.value,
                        )}
                      />
                    );
                  }}
                </Field>
                <ErrorText>{error}</ErrorText>

                {/* Emails checkboxes */}
                <CheckboxList config={checkboxes} role={role} />
              </div>
              {isSeller(role) && (
                <div className="mx-auto my-[12px]">
                  <Icons iconName="DoubleArrow" colour="complement" size="24" />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};
