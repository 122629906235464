import * as Sentry from "@sentry/react";
import { api } from "../../../service/ApiController";

import { paths } from "@shared/types.d";

export type AssignLawyerGroupToTransactionsGroupBody =
  paths["/api/transaction/lawyer-group/{groupId}"]["post"]["requestBody"]["content"]["application/json"];

export const assignLawyerGroupTransactionsGroup = async (
  groupId: string,
  body: AssignLawyerGroupToTransactionsGroupBody,
): Promise<void> => {
  try {
    const response = await api.post(
      `/transaction/lawyer-group/${groupId}`,
      body,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);

    throw new Error(
      `Lawyer groups assignment to transactions group failed: ${error}`,
    );
  }
};
