import { useState } from "react";

import { OpsActions } from "./OpsActions";

export const OpsDetails = () => {
  const [activeEntity, setActiveEntity] = useState<
    "Estate Agent" | "Solicitor" | null
  >(null);

  return (
    <>
      <OpsActions
        entity="Estate Agent"
        isModalOpen={activeEntity === "Estate Agent"}
        setIsModalOpen={(isOpen) =>
          isOpen ? setActiveEntity("Estate Agent") : setActiveEntity(null)
        }
      />
      <OpsActions
        entity="Solicitor"
        isModalOpen={activeEntity === "Solicitor"}
        setIsModalOpen={(isOpen) =>
          isOpen ? setActiveEntity("Solicitor") : setActiveEntity(null)
        }
      />
    </>
  );
};
