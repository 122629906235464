import Typography from "@/foundation/Typography/Typography";
import Icons from "@/foundation/Icons/Icons";

interface WarningCardProps {
  text: string;
}
export const WarningCard = ({ text }: WarningCardProps) => (
  <div className="flex justify-center items-center p-[16px] bg-ui-warning-100 rounded-xl">
    <div className="shrink-0 mr-[12px]">
      <Icons iconName="Warning" size="24" />
    </div>
    <Typography variant="sm" weight="semi-bold">
      {text}
    </Typography>
  </div>
);
