import { api } from "../../../service/ApiController";

import { paths } from "@shared/types.d";
import { AdminTransaction } from "../../pages/TransactionDetails/types";

export type AssignLawyerGroupBody =
  paths["/api/transaction/{id}/lawyer-group"]["post"]["requestBody"]["content"]["application/json"];

export const assignLawyerGroupTransaction = async (
  transactionId: string,
  body: AssignLawyerGroupBody,
): Promise<AdminTransaction> => {
  try {
    const response = await api.post(
      `/transaction/${transactionId}/lawyer-group`,
      body,
    );
    return response.data;
  } catch (error) {
    throw new Error(`Lawyer groups assignment failed: ${error}`);
  }
};
