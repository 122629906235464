import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

import { api } from "../../../service/ApiController";
import { operations } from "@shared/types.d";

type TransactionsGroupsApiResponse =
  operations["TransactionsGroupController_getTransactionsGroupByName"]["responses"]["200"]["content"]["application/json"];

export const findTransactionsGroup = async (
  name: string,
): Promise<TransactionsGroupsApiResponse> => {
  try {
    const response = await api.get(`/transactions-group/${name}`);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status !== 404) {
        Sentry.captureException(error);
        throw new Error(
          error.response?.data?.message ||
            "There was an error finding transactions group",
        );
      }
    }
    throw error;
  }
};
