import { useState } from "react";
import { z } from "zod";
import { FieldArray, Formik } from "formik";

import { Modal } from "../../../../components/Modal/Modal";
import { LawyerGroup } from "../../../../types";
import { withZodSchema } from "formik-validator-zod";
import { Input } from "../../../../components/Formik/Input/Input";
import { Button } from "@/components/Button/Button";
import { ApiController } from "../../../../service/ApiController";
import ErrorText from "@/foundation/Typography/ErrorText";

interface LawyerGroupThirdfortModalProps {
  onClose: () => void;
  onUpdated: (newGroup: LawyerGroup) => void;
  lawyerGroup: LawyerGroup;
}

export const lawyerGroupThirdfortForm = z.object({
  thirdfortEmails: z.array(z.string().email()).min(1),
});

export const LawyerGroupThirdfortModal = ({
  onClose,
  onUpdated,
  lawyerGroup,
}: LawyerGroupThirdfortModalProps) => {
  const [error, setError] = useState("");

  const onsubmit = async (values: z.infer<typeof lawyerGroupThirdfortForm>) => {
    try {
      setError("");
      const updatedLawyerGroup = await ApiController.updateLawyerGroup(
        lawyerGroup._id,
        {
          law_firm: lawyerGroup.law_firm._id,
          solicitor: lawyerGroup.solicitor._id,
          legal_assistants: lawyerGroup.legal_assistants?.map(
            (assistant) => assistant._id,
          ),
          invoicing_address: lawyerGroup.invoicing_address,
          thirdfortRecipientEmails: values.thirdfortEmails,
          panel_solicitor: lawyerGroup.panel_solicitor,
        },
      );

      onUpdated(updatedLawyerGroup);
    } catch {
      setError("Failed to update thirdfort details");
    }
  };

  return (
    <Formik
      initialValues={{
        thirdfortEmails: lawyerGroup.thirdfortRecipientEmails || [""],
      }}
      validate={withZodSchema(lawyerGroupThirdfortForm)}
      onSubmit={onsubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Modal
          title="Thirdfort Details"
          cancelButtonFunction={onClose}
          saveButtonFunction={handleSubmit}
          isSaveButtonDisabled={isSubmitting}
        >
          <FieldArray name="thirdfortEmails">
            {({ remove, push }) => {
              const removeButtonDisabled = values.thirdfortEmails.length === 1;
              return (
                <div>
                  {values.thirdfortEmails.length > 0 &&
                    values.thirdfortEmails.map((_, index) => (
                      <div key={index} className="flex mb-[8px]">
                        <Input
                          fieldName={`thirdfortEmails.${index}`}
                          label="Requester email address"
                        />
                        <button
                          className={`ml-[16px] mt-[-24px] ${removeButtonDisabled ? "cursor-not-allowed text-ui-black-50" : "cursor-pointer"}`}
                          onClick={() => remove(index)}
                          disabled={removeButtonDisabled}
                        >
                          X
                        </button>
                      </div>
                    ))}
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => push("")}
                    className="mt-[16px] w-[160px]"
                  >
                    Add email address
                  </Button>
                </div>
              );
            }}
          </FieldArray>
          {error && <ErrorText>{error}</ErrorText>}
        </Modal>
      )}
    </Formik>
  );
};
