import Typography from "@/foundation/Typography/Typography";
import { Button } from "@/components/Button/Button";

interface ConfirmCardProps {
  setDisplayConfirmCard: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmAssign: () => void;
  type: string;
  extraInfo?: React.ReactNode;
}

export const ConfirmCard = ({
  setDisplayConfirmCard,
  handleConfirmAssign,
  type,
  extraInfo,
}: ConfirmCardProps) => (
  <div className="bg-brand-warmth-50 flex flex-col rounded-xl mt-[40px] mb-[32px] md:mb-[16px] md:mr-[16px] pb-[24px] max-w-[548px] md:min-w-[320px]">
    <div className="mt-[24px] px-[32px]">
      <Typography
        type="h2"
        variant="xl"
        weight="bold"
        className="text-brand-heavy-teal-100"
      >
        Are you sure?
      </Typography>
      <Typography
        type="p"
        variant="base"
        weight="regular"
        className="text-brand-heavy-teal-100 mt-[16px]"
      >
        Double check your {type} and confirm.
      </Typography>
      {extraInfo && <div className="mt-[16px]">{extraInfo}</div>}
      <div className="flex mt-[16px]">
        <Button
          variant="primary"
          type="button"
          onClick={handleConfirmAssign}
          className="mt-[16px]"
          size="full"
          name="Yes, I'm sure. Assign solicitors"
        >
          Yes, I&apos;m sure. Assign {type}
        </Button>
      </div>
      <div className="flex mb-[16px]">
        <Button
          variant="secondary"
          type="button"
          onClick={() => setDisplayConfirmCard(false)}
          className="mt-[16px]"
          size="full"
        >
          Go back and amend
        </Button>
      </div>
    </div>
  </div>
);
